#ada-top-nav{
    background-color: #ffffff !important;
    border-bottom: 4px solid #005096 !important;
    margin:0px;
    position: fixed;
    z-index: 999;
    width: 100%;
}

#ada-top-nav-brand,
#ada-top-nav-brand:active, 
#ada-top-nav-brand:visited,
#ada-top-nav-brand:hover{
    text-decoration: none;
    color: #f0781e;
    font-weight: 500;
}

#ada-top-nav #user-burger-menu{
    background-color:none !important;
}

#ada-top-nav #user-search_button {
    background-color:none !important;
}

#ada-top-nav #user-search_button:hover {
    background-color:none !important;
}

#ada-top-nav #user-burger-menu #user-burger-menu_button{
    background-color:unset !important;
    border:unset !important;
    outline: 0;
    padding: 4px;
    color:#000 !important;
}

#ada-top-nav #user-burger-menu #user-burger-menu_button:focus
#ada-top-nav #user-burger-menu #user-burger-menu_button:active{
    background-color:#fff !important;
}

#ada-top-nav #user-burger-menu .dropdown-menu .dropdown-item .ada-dd-top-nav-icon{
    margin-right: 10px;
    width: 18px;
    margin-top: -3px;
}

#ada-top-nav #user-burger-menu #user-burger-menu_button #user-avatar{

    background-color:unset !important;
}
#ada-top-nav #user-settings{
    background-color:unset !important;
    border:unset !important;
    padding: 4px;
    margin-left: 2px;
}

#ada-logo {
    width: 60px;
    height: 50px;
}
