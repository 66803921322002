#ada-settings-content {
    margin-left: 250px;
    margin-top: 35px;
    padding-top: 0px;
    min-height: 100%;
    float: right;
    position: absolute;
    right: 0px;
}

#ada-settings-side-nav-left {
    margin-top: -4px;
    border-right:1px solid #e6e6e6;
    padding:0px;
    height: 100%;
    position: fixed;
    width: 500px;
    min-height: 100vh;
}

#ada-settings-side-container{
    margin-top:20px;
    background-color:#fdfdfd;
}

#ada-settings-side-container #ada-header{
    border-bottom: 1px solid #e6e6e6;
}

#ada-settings-side-container #ada-header{
    padding:10px;
    border-bottom: 1px solid #e6e6e6;
}
#ada-settings-side-container #ada-main-side-nav{
    border:0px;
    height: 100vh;
    margin-top:5px;
}

#ada-settings-side-container #ada-dataset-recently-viewed {
    padding:10px;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

#ada-settings-side-container #ada-main-side-nav .nav-item{
    padding-top:15px;
    padding-left:15px;
    padding-bottom:10px;
    font-size:10pt;
    font-weight:600;
    border-left:5px solid #fdfdfd;
}

#ada-settings-side-container #ada-main-side-nav .nav-item:hover{
    background-color:#f0f0f0;
    color:#f0781e;
    cursor:pointer;
}

#ada-settings-side-container #ada-main-side-nav .nav-item .ada-side-nav-icon{
    width:38px;
    margin-right:15px;
    margin-top:-3px;
    border-radius:6px;
}
#ada-settings-side-container #ada-main-side-nav .ada-main-side-nav-item-selected-1{
    background-color:#f0f0f0;
    border-left:5px solid #f0781e;
    color:#f0781e;
    cursor:pointer;

}

#aada-settings-side-container #ada-main-side-nav #ada-main-side-nav-org-settings{
    bottom: 0;
    position: absolute;
    width: 100%;
}

#ada-settings-side-container #ada-main-side-nav #ada-main-side-nav-org-catalog{
    border-bottom:1px solid #cecece;
}

#ada-settings-side-container #ada-main-side-nav #ada-main-side-nav-org-catalog:hover{
    border-bottom:1px solid #cecece;
    background: none;
}

#ada-button-options .dropdown-toggle::after, .dropdown-toggle::before{

    content: none !important;
    outline: 0;
}

#add-member-btn {
    background-color: unset !important;
    outline: 0 !important; 
    color: #000 !important;
    border: none;
}

.user-avatar {
    width: 32px;
    border-radius: 300px;
}


#edit-profile-btn {
    background-color:unset !important;
    border:unset !important;
    outline: 0;
}

#btn-icon {
    background-color:unset !important;
    border:unset !important;
    color: inherit;
}

table {
    color: inherit !important;
}
table tbody {
    height: 200px;
}
table thead tr th{
    line-height: 35px;

}
table tbody tr td {
    border: 0px !important;
}

table tbody tr:hover {
    background-color: #eee !important;
}


#ada-user-autosuggest {
    margin-top: 2px;
    padding: 0px !important;
    white-space: nowrap;
    background: #fff;
    z-index: 999;
    position: absolute;
    min-width: 220px;
    max-height: 320px;
    box-shadow: 1px 1px 1px 1px #ccc;
    overflow-y: auto;
    overflow-x: hidden;
}

#ada-user-autosuggest li {
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
}

#btn-icon {
    background-color:unset !important;
    border:unset !important;
    color: inherit;
}

#ada-group-autosuggest {
    margin-top: 2px;
    padding: 0px !important;
    white-space: nowrap;
    background: #fff;
    z-index: 111;
    position: absolute;
    min-width: 320px;
    max-height: 300px;
    box-shadow: 1px 1px 1px 1px #ccc;
    overflow-y: auto;
    overflow-x: hidden;
}

#ada-group-autosuggest li {
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
}

#ada-org-autosuggest {
    margin-top: 2px;
    padding: 0px !important;
    white-space: nowrap;
    background: #fff;
    z-index: 111;
    position: absolute;
    min-width: 320px;
    max-height: 300px;
    box-shadow: 1px 1px 1px 1px #ccc;
    overflow-y: auto;
    overflow-x: hidden;
}

#ada-org-autosuggest li {
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
}


#ada-group-grant-access {
    display: none;
}


#ada-org-grant-access {
    display: none;
}