/* Extra small devices (phones, 600px and down) */
#ada-login{
    margin: 0 auto;
    width: 800px;
    padding-top:25vh;
}

#ada-login form h3{
    font-size:18pt;
    font-weight:500;
    margin-bottom:0px;
    color:#005096;
}
#ada-login form{
    margin: 0px !important;
    padding: 35px 15px 35px 15px;
    background: #fff;
    font-size: 12px;
}

#ada-login-main {
    border: 1px solid #e6e6e6;
    height: 320px; 
}

#ada-login-container {
    padding: 0px !important;
}

#ada-login-header #ada-login-title{
    color: #f0781e;
}

#ada-login #ada-login-header{
    margin-bottom:10px;
    font-size: 18pt;
}
#ada-login-guide {
    font-size:12pt;
    font-weight:300;
    color:#b4b4b4;
    font-size: 12px;
}

#ada-login-btn {
    background: #005096;
}

#ada-login-photo-div {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

#ada-login-photo {
    width:100%; 
    height: 320px;
    min-height:auto; 
    /* background-image: url('../assets/login-photo.jpg'); 
    width: 100;
    height: 200; */
}

#checkbox-accept-terms {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

#accept-terms {
    display: inline-block;
    margin-top: 30px;
    cursor: pointer;
}

#terms-and-condition {
    color: #f0781e;
    text-decoration: none;
    font-weight: 600;
}