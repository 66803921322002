html {
    font-size: 14px;
    overflow-y: scroll;
}

body {
    color: #373D3F;
}

.App {
    min-height: 100vh;
    background: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}

#ada-main-container{
    margin:0px;
    min-height:100vh;
    background: #fff;
    padding-top: 80px;
}

#ada-main-content {
    margin-top: 60px;
    padding-top: 0px;
    min-height: 100%;
    float: right;
    position: absolute;
    right: 0px;
}

#ada-main-side-nav-left{
    margin-top: 35px;
    border-right:1px solid #e6e6e6;
    height: 100%;
    position: fixed;
    width: 500px;
    height: 100%;
    overflow-y: auto;
    top: 300;
    padding-bottom: 0px !important;
}

.ada-page-title{
    color: #f0781e;
}

.ada-page-link{
    font-weight: 600;
    color: #f0781e;
}

#ada-main-side-container{
    margin-top:20px;
    background-color:#fdfdfd;
    margin-bottom: 130px;
}

#ada-main-side-container #ada-header{
    padding:10px;
    border-bottom: 1px solid #e6e6e6;
}
#ada-main-side-container #ada-main-side-nav{
    border:0px;
    margin-top:5px;
}

#ada-main-side-container #ada-dataset-recently-viewed {
    border-top: 1px solid #e6e6e6;
}

 #ada-dataset-recently-viewed-title{
    border-bottom: 1px solid #e6e6e6;
    padding-top: 10px;
    padding-bottom: 10px;
}

#ada-main-side-container #ada-dataset-recently-viewed-title-text {
    margin-left: 12px;
}


#ada-main-side-container #ada-main-side-nav .nav-item{
    padding-top:15px;
    padding-left:15px;
    padding-bottom:10px;
    font-size:10pt;
    font-weight:600;
    border-left:5px solid #fdfdfd;
}

#ada-main-side-container #ada-main-side-nav .nav-item:hover{
    background-color:#f0f0f0;
    color:#f0781e;
    cursor:pointer;
}

#ada-main-side-container #ada-main-side-nav .nav-item .ada-side-nav-icon{
    width:38px;
    margin-right:15px;
    margin-top:-3px;
    border-radius:6px;
}
#ada-main-side-container #ada-main-side-nav .ada-main-side-nav-item-selected-1{
    background-color:#f0f0f0;
    border-left:5px solid #f0781e;
    color:#f0781e;
    cursor:pointer;

}

#ada-main-side-container #ada-main-side-nav #ada-main-side-nav-org-settings{
    bottom: 0;
    position: absolute;
    width: 100%;
}

#ada-main-side-container #ada-main-side-nav #ada-main-side-nav-org-catalog{
    border-bottom:1px solid #cecece;
}

#ada-main-side-container #ada-main-side-nav #ada-main-side-nav-org-catalog:hover{
    border-bottom:1px solid #cecece;
    background: none;
}

#ada-main-side-nav-org-catalog-add{
    float:right;
    width: 24px;
    margin-right: 15px;
}

.no-padding-left{
    padding-left:0px !important;
}
.no-padding-right{
    padding-right:0px !important;
}
.no-padding{
    padding:0px !important;
}
.align-center{
    text-align: center;
}
.align-right{
    text-align: right;
}
.align-left{
    text-align: left;
}
.btn{
    padding-left:20px;
    padding-right:20px;
}
.form-label{
    font-size:10pt;
}
.btn-primary{
    background-color: #f0781e;
    border:1px solid #f0781e;
    border-radius: 0px !important;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #f0781e;
    border:1px solid #f0781e;
}

.btn-outline-primary {
    border:1px solid #f0781e;
    color: #f0781e;
}

.btn-primary:hover, .btn-primary:focus{
    background-color: #de6c17;
    border:1px solid #de6c17;
}
.btn-blue{
    background-color: #005096;
    border:1px solid #005096;
    color:#FFF;
}
.btn-blue:hover, .btn-blue:focus{
    color:#FFF;
    background-color: #02447e;
    border:1px solid #02447e;
}

input, select, .btn .close, button{
    outline-color: transparent !important;
    outline-style: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.modal-open {
    overflow: scroll;
}

.tooltip {
    z-index: 999;
}

.alert-fixed {
    position:fixed; 
    width: 50%;
    top: 0px; 
    left: 25%;
    z-index:9999; 
    margin-top: 10px;
}

#ada-loader {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

#loader-image {
    position: absolute;
    color: #fff;
    z-index: 999;
    top: 50%;
    left: 50%;
}

#image-loader-div {
    width: 133px;
    height: 133px;
    color: #000;
}

#image-loader {
    margin-top: 50%;
}

#pagination-dropdown {
    width: 90px;
}

#profile-image {
    border: 1px solid #000;
}

#group-card {
    width: '18rem'; 
    height: '10rem';
}

#btn-icon {
    background-color:unset !important;
    border:unset !important;
    color: inherit;
}

#ada-dataset-option-btn-icon {
    background-color:unset !important;
    border:unset !important;
    color: inherit;
}

#ada-dataset-option-btn-icon:hover {
    border:unset !important;
    color: inherit;
    background: #ddd !important;
}

#ada-dataset-details {
    background: #f8f8f8;
    padding: 10px;
    border: 1px solid #eee;
    margin: 0 !important;
}

.ada-main-dataset-tags{
    padding: 3px 10px 3px 10px;
    font-size: 9pt;
    font-weight: 400;
    margin-top: 15px;
    margin-left: 5px;
    background-color:#005096 !important;
}

#ada-dataset-quality-icon {
    width: 40px;
    height: 40px;
    margin-top: 5px;
}

#ada-dataset-panels #dataset-description {
    min-height: 250vh;
    overflow: auto;
}

#ada-data-dictionary {
    overflow: auto;
    width: 2000px;
}

#ada-dataset-link {
    background: #eee;
}

.ada-dataset-link-input-field {
    margin: 2px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    line-height: 1.5;
    font-size: "1rem";
    font-weight: 400;
    color: "#495057";
    padding: .375rem .75rem;
    height: calc(1.5em + .75rem + 2px);
    width: inherit;

}

#ada-dataset-panels {
    border-bottom: 1px solid #dee;
    margin-bottom: 24px;
}

#ada-dataset-panels-nav {
    width: inherit;
    padding: 0px !important;
}

#ada-dataset-panels .nav-item{
    border-top: 1px solid #dee;
    border-left: 1px solid #dee;
    border-right: 1px solid #dee;
    padding: 5px;
    font-weight: 600;
    min-width: 150px;
    text-align: center;
}


#ada-dataset-panels .nav-item:hover{
    color: #f0781e;
    font-weight: 600;
    cursor: pointer;
    background: #efefef;
}

.active-nav {
    color: #f0781e;
    font-weight: 600;
    cursor: pointer;
    background: #ddd;
}

#ada-dataset-name {
    font-weight: 600;
    cursor: pointer;
}

#ada-dataset-name:hover {
    font-weight: 600;
    color: #de6c17;
}

#ada-dataset-name-trash {
    font-weight: 600;
}

#ada-my-organization {
    position: fixed;
    padding-top: 75px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    background: #eee;
    z-index: 111;
    height: 130px;
}

#ada-my-org-details {
    margin-top: 5px;    
}

.nav-tabs .nav-link {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

#ada-data-catalog {
    border: 1px solid #eee;
}

#ada-dataset-manage-access-modal .modal-body{
    padding: 0px !important;
    height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#ada-create-dataset-modal .modal-body {
    padding: 0px !important;
    height: 450px;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

#ada-dataset-data-dictionary-modal .modal-body {
    padding: 0px !important;
    height: 450px;
    overflow-y: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

#dataset-public-types {
    margin-left: 40px;
}

#ada-dataset-search {
    position: relative;
    width: 500px;
}

#search-dataset-result{
    position: relative;
    margin-left: 0px !important;

}

#ada-user-grant-access #role{
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
}


#ada-dataset-autosuggest {
    margin: 2px;
    padding: 0px !important;
    white-space: nowrap;
    background: #fff;
    z-index: 111;
    position: absolute;
    min-width: 500px;
    max-height: 300px;
    box-shadow: 1px 1px 1px 1px #ccc;
    overflow-y: hidden;
    overflow-x: hidden;

}

#ada-dataset-autosuggest li {
    list-style-type: none;
    border: 1px solid #eee;
    padding: 10px;
    cursor: pointer;
}

#ada-dataset-filter {
    margin-top: 10px;
    padding: 10px;
    background: #ddd;
}
.filter-organization {
    min-width: 200px;
}

.filter-group {
    min-width: 250px;
}

#quality_id {
    min-width: 210px;
}

#ada-dataset-description {
    border: 1px solid #ddd;
    padding: 15px;
}

#profile-upload-photo {
    display: block;
}

#profile-upload-photo-btn {
    position: relative;
    background: #000;
    color: #fff;
    opacity: 0.5;
    top: -40px;
    width: 200px;
    padding: 10px;
    cursor: pointer;
    display: none;
}

.attachment-form {
    margin-top: 5px;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #eee;
}

.attachment-loader{
    display: none;
}

#ada-dataset-permission-modal-title {
    font-size: 12px !important;
}

.ada-dataset-search-result {
    border-top: 1px solid #eee;
    border-bottom: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
    cursor: pointer;
}

.ada-dataset-search-result:hover {
    background: #eee;
}

#ada-search-dataset-btn {
    border: 1px solid #ccc !important;
    border-bottom-left-radius: 5px;
    background-color: #eee !important;
    color: #000
}

#ada-dataset-access-role {
    margin-bottom: 0px !important;
}

#btn-toggle-dropdown:after {
    display: none;
}

#ada-terms-condition {
    padding-top: 80px;
    margin-bottom: 20px;
}

.ada-terms-and-condition-section-title {
    font-weight: 400;
    color: #527DFE
}